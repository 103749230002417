import React from 'react';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

const SelectInstituicoesFederais = () => {

    const apenasInstitutos = useSelector(state => state.senac.apenasInstitutos);
    const estado = useSelector(state => state.senac.estado)
    
    const [defaultSwitch, setDefaultSwitch] = useState(apenasInstitutos);
    const history = useHistory();
    const dispatch = useDispatch();

    const toggleHandler = () => {
        dispatch({ type: 'SET_APENAS_INSTITUTO', value: !defaultSwitch });
        setDefaultSwitch(!defaultSwitch);
        history.push(`/concorrencia/${estado}`);
    };

    return (
        <div className="sel-institutos" text-right="true">            
            <Form.Group>
              <div className="switch d-inline m-r-10">
                <Form.Control
                  className="switch-custom form-control"
                  type="checkbox"
                  id="checked-default"
                  defaultChecked={defaultSwitch}
                  onChange={toggleHandler}
                  style={{ height: 0, overflow: 'hidden' }}
                />
                <Form.Label htmlFor="checked-default" className="cr" />
              </div>
              <Form.Label>Exibir apenas concorrentes Institutos Federais</Form.Label>
            </Form.Group>
        </div>
    );
}

export default SelectInstituicoesFederais;