import {axios, abortController} from '../../../config/axios-config'

const getCursosRelacionados = async (ocupacao, cursoSeletorOcupacao) => {
    try {
        let response = null
        response = await axios.post('/retornaCursosRelacionados', {ocupacao: ocupacao, cursos: cursoSeletorOcupacao}, {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
        throw new Error('Erro na getCursosRelacionados.')
    }
}
export default getCursosRelacionados