import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import getVersaoAtual from './connection/getVersaoAtual';

const ModalVersaoAtual = () => {
    const [showModal, setShowModal] = useState(false);

    const [versaoAtual, setVersaoAtual] = useState()
    

    useEffect(() => {

        const setVersao = async () => {
            // Recupera a versão do localStorage
            const versaoLocalStorage = localStorage.getItem('versao');

            const versao = await getVersaoAtual()

            setVersaoAtual(versao)

            // Se não existir ou for diferente da versão atual, exibe o modal
            console.log(versaoLocalStorage, versao?.versao)
            if (versaoLocalStorage !== versao?.versao) {
                setShowModal(true);
                localStorage.setItem('versao', versao?.versao);
            }
        }

        setVersao();
       
        
    }, []);

    const modal = (
        <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Sistema atualizado: Versão {versaoAtual?.versao}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{versaoAtual?.data}</p>
                <p>
                    <div dangerouslySetInnerHTML={{ __html: versaoAtual?.conteudo }} />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

    return modal

}

export default ModalVersaoAtual;