import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getSetorAgregado from '../../views/visao_setorial/connection/getSetorAgregado';
import DropdownCustomStyle from './DropdownCustomStyle';
import Select from "react-select";
import { Form } from 'react-bootstrap';

const DropdownSetorAgrupado = () => {

  const dispatch = useDispatch();
  const setorAgregado = useSelector(state => state.senac.setorAgregado);
  const [listaSetorAgregado, setListaSetorAgregado] = useState(["Não tem nenhum setor agrupado no sistema"])
  const [selectedOption, setSelectedOption] = useState(setorAgregado);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let auxListaSetorAgregado = await getSetorAgregado();
        if (auxListaSetorAgregado !== undefined) {
          auxListaSetorAgregado.unshift({divisao_cod_agg: 'all', divisao_nome_agg:"Todos os setores"});
          setListaSetorAgregado(auxListaSetorAgregado);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedOption(setorAgregado)
  }, [setorAgregado]);

  return (
    <div className="divDropdown">
      <Form.Label className='divDropDownTitle'>Setor</Form.Label>
      <Select
        isSearchable={true}
        options={listaSetorAgregado.map((setor) => {
          return { value: setor.divisao_cod_agg, label: setor.divisao_nome_agg }
        })}
        value={selectedOption}
        onChange={(e) => {
          dispatch({ type: 'SET_SETOR_AGREGADO', value: e });
          setSelectedOption(e);
        }}
        className=".dropdown-item-Custom col-dropdown-select"
        classNamePrefix="select"
        styles={DropdownCustomStyle}
      />
    </div>
  );
};

export default DropdownSetorAgrupado;