import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getOcupacoesCNCT from '../../views/perfil_trabalhadores/connection/getOcupacoesCNCT';
import getOcupacoesQuali from '../../views/perfil_trabalhadores/connection/getOcupacoesQuali';
import DropdownCustomStyle from './DropdownCustomStyle';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap';

const DropdownOcupacoes = () => {
  const dispatch = useDispatch();
  const ocupacao = useSelector(state => state.senac.ocupacao)
  const cursoSeletorOcupacao = useSelector(state => state.senac.cursoSeletorOcupacao);
  const provenienteQuali = useSelector(state => state.senac.provenienteQuali);

  const history = useHistory();
  const location = history.location;
  const path = location.pathname;

  const [ocupacoesCNCT, setOcupacoesCNCT] = useState(['Não tem nenhuma ocupação no sistema']);
  const [selectedOption, setSelectedOption] = useState(ocupacao);

  useEffect(() => {
    const fetchDataTecnico = async () => {
      try {
        let listaOcupacoes = await getOcupacoesCNCT(cursoSeletorOcupacao);
        if(cursoSeletorOcupacao[0].value === "all") {
          listaOcupacoes.unshift({NU_CODIGO_CBO: 'all', NM_OCUPACAO:'Todas as ocupações'});
        }
        else {
          listaOcupacoes.unshift({NU_CODIGO_CBO: 'some', NM_OCUPACAO:'Todas as ocupações relacionadas aos cursos'});
        }
        if (listaOcupacoes !== undefined) {
          setOcupacoesCNCT(listaOcupacoes);
        }
        if(provenienteQuali){
          dispatch({ type: 'SET_OCUPACAO', value: {value: '513205', label: 'Cozinheiro geral'} });
          dispatch({ type: 'SET_PROVENIENTE_QUALI', value: false });
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchDataQualificacao = async () => {
      try {
        let listaOcupacoes = await getOcupacoesQuali();
        listaOcupacoes.unshift({NU_CODIGO_CBO: 'allQuali', NM_OCUPACAO:'Todas as ocupações'});
        if (listaOcupacoes !== undefined) {
          setOcupacoesCNCT(listaOcupacoes);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Modificar aqui qual categoria vai recuperar os dados "qualificacao_profissional"
    if(path.includes("tecnicos")) {
      fetchDataTecnico();
    } else {
      fetchDataQualificacao();
    }
  }, [path]);

  useEffect(() => {
    const fetchDataTecnico = async () => {
      try {
        let listaOcupacoes = await getOcupacoesCNCT(cursoSeletorOcupacao);
        if(cursoSeletorOcupacao[0].value === "all") {
          listaOcupacoes.unshift({NU_CODIGO_CBO: 'all', NM_OCUPACAO:'Todas as ocupações'});
        }
        else {
          listaOcupacoes.unshift({NU_CODIGO_CBO: 'some', NM_OCUPACAO:'Todas as ocupações relacionadas aos cursos'});
        }
        if (listaOcupacoes !== undefined) {
          setOcupacoesCNCT(listaOcupacoes);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if(path.includes("tecnicos")) {
      fetchDataTecnico();
    }
  }, [cursoSeletorOcupacao]);

  const codigoCbo = `${ocupacao?.value}`

  useEffect(() => {
    setSelectedOption(ocupacao)
  }, [codigoCbo]);


  return (
    <div className="divDropdown">
      <Form.Label className='divDropDownTitle'>Ocupação</Form.Label>
      <Select
        isSearchable={true}
        options={ocupacoesCNCT?.map((ocupacao) => {
          return { value: ocupacao.NU_CODIGO_CBO, label: ocupacao.NM_OCUPACAO };
        })}
        value={selectedOption}
        onChange={(e) => {
          dispatch({ type: 'SET_OCUPACAO', value: e });
          setSelectedOption(e);
        }}
        className="dropdown-item-Custom col-dropdown-select"
        classNamePrefix="select"
        styles={DropdownCustomStyle}
      />
    </div>
  );
};

export default DropdownOcupacoes