import {axios, abortController} from '../../../config/axios-config'
import store from '../../../../store/index'

const getCursosCNCT = async () => {
    try {
        let response = null
        response = await axios.post('/retornaCursosCNCT', {cancelToken: abortController.token, timeout: 60000})
        store.dispatch({ type: 'GET_LISTA_Cursos_CNCT', list: response.data })
        return response.data
    } catch (error) {
        console.log(error)
    }
}
export default getCursosCNCT