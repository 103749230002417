import {axios, abortController} from '../../../config/axios-config'

const getOcupacoesCNCT = async (cursoSelecionado) => {
    try {
        let response = null
        response = await axios.post('/retornaOcupacoesCNCT', {curso: cursoSelecionado}, {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
        throw new Error('Erro na getOcupacoesCNCT.')
    }
}
export default getOcupacoesCNCT