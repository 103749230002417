import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getCursosCNCT from '../../views/concorrencia/connection/getCursosCNCT';
import Select from "react-select";
import DropdownSeletorCursoCustomStyle from './DropdownSeletorCursoCustomStyle';
import { Form } from 'react-bootstrap';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const DropdownSeletorCursos = () => {

    const dispatch = useDispatch();

    const cursoSeletorOcupacao = useSelector(state => state.senac.cursoSeletorOcupacao);

    const [cursosCNCT, setCursosCNCT] = useState(["Não tem nenhum curso no sistema"])
    const [selectedOptions, setSelectedOptions] = useState(cursoSeletorOcupacao);
    const [enableButton, setEnableButton] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let listaCursos = await getCursosCNCT();
                if (listaCursos !== undefined) {
                    listaCursos.unshift({ CODIGO_CURSO: 'all', Titulo: 'Todos os cursos técnicos' });
                    setCursosCNCT(listaCursos);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

    }, []);

    useEffect(() => {
        setSelectedOptions(cursoSeletorOcupacao)
    }, cursoSeletorOcupacao);

    return (
        <>
            <div className="col-dropdown-select flex-grow-1">
                <div className="divDropdown">
                    <Form.Label className='divDropDownTitle'>Curso</Form.Label>
                    <Select
                        isSearchable={true}
                        placeholder="Selecione os cursos..."
                        options={cursosCNCT.map((curso) => {
                            return { value: curso.CODIGO_CURSO, label: curso.Titulo }
                        })}
                        onChange={(selected) => {
                            let auxSelecionados = [];
                            if(selectedOptions.length === 1 && selectedOptions[0].value === "all" && selected.length > 1)
                            {
                                auxSelecionados.push(selected[1]);
                            } else if(selected.length > 1 && selected[selected.length - 1].value === "all") {
                                auxSelecionados.push({value: 'all', label:'Todos os cursos técnicos'})
                            } else {
                                auxSelecionados = selected;
                            }
                            setSelectedOptions(auxSelecionados);
                            
                            let equalArrays = true;
                            if (auxSelecionados.length !== cursoSeletorOcupacao.length) {
                                equalArrays = false;
                            } else {
                                for (let i = 0; i < auxSelecionados.length; i++) {
                                    if (auxSelecionados[i].value !== cursoSeletorOcupacao[i].value) {
                                        equalArrays = false;
                                        break;
                                    }
                                }
                            }
                            if (equalArrays) {
                                setEnableButton(false)
                            } else {
                                setEnableButton(true)
                            }
                        }}
                        value={selectedOptions}
                        isMulti
                        className="dropdown-item-Custom col-dropdown-select"
                        classNamePrefix="select"
                        styles={DropdownSeletorCursoCustomStyle}
                    />
                </div>
            </div>
            <div className='col-dropdown-button-relacionado'>
                <div className="divDropdown-button align-items-center" data-custom-pp="seletorCurso">
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip id={`tooltip-bottom`} className="custom-tooltip">Carregar dashboard</Tooltip>}
                    >
                        <Button
                            variant='success'
                            id={`dropdown-variants-success`}
                            key='success'
                            className={`drp-icon drp-button ${!enableButton ? 'disabled-transparent-button' : ''}`}
                            disabled={!enableButton}
                            onClick={() => {
                                let allSelected = false;
                                setEnableButton(false)
                                for (let i = 0; i < selectedOptions.length; i++) {
                                    if (selectedOptions[i].label === 'Todos os cursos técnicos') {
                                        allSelected = true;
                                        break;
                                    }
                                }
                                if (allSelected || selectedOptions.length === 0) {
                                    dispatch({ type: 'SET_CURSO_SELETOR_OCUPACAO', lista: [{ value: 'all', label: 'Todos os cursos técnicos' }] });
                                    dispatch({ type: 'SET_OCUPACAO', value: { value: 'all', label: 'Todas as ocupações' } });
                                } else {
                                    dispatch({ type: 'SET_CURSO_SELETOR_OCUPACAO', lista: selectedOptions });
                                    dispatch({ type: 'SET_OCUPACAO', value: { value: 'some', label: 'Todas as ocupações relacionadas aos cursos' } });
                                }
                            }}
                        >
                            <i className={`feather icon-check-circle ${!enableButton ? 'icon-not-disabled' : ''}`} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
        </>
    );
};

export default DropdownSeletorCursos;