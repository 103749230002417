import {axios, abortController} from '../../../config/axios-config'

const getOcupacoesQuali = async () => {
    try {
        let response = null
        response = await axios.post('/retornaOcupacoesQuali', {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
        throw new Error('Erro na getOcupacoesQuali.')
    }
}
export default getOcupacoesQuali