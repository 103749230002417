const nomeEstados = (sigla) => {
    const estados = {
        AC: { nome: "Acre", artigo: "no" },
        AL: { nome: "Alagoas", artigo: "em" },
        AP: { nome: "Amapá", artigo: "no" },
        AM: { nome: "Amazonas", artigo: "no" },
        BA: { nome: "Bahia", artigo: "na" },
        CE: { nome: "Ceará", artigo: "no" },
        DF: { nome: "Distrito Federal", artigo: "no" },
        ES: { nome: "Espírito Santo", artigo: "no" },
        GO: { nome: "Goiás", artigo: "em" },
        MA: { nome: "Maranhão", artigo: "no" },
        MT: { nome: "Mato Grosso", artigo: "no" },
        MS: { nome: "Mato Grosso do Sul", artigo: "no" },
        MG: { nome: "Minas Gerais", artigo: "em" },
        PA: { nome: "Pará", artigo: "no" },
        PB: { nome: "Paraíba", artigo: "na" },
        PR: { nome: "Paraná", artigo: "no" },
        PE: { nome: "Pernambuco", artigo: "em" },
        PI: { nome: "Piauí", artigo: "no" },
        RJ: { nome: "Rio de Janeiro", artigo: "no" },
        RN: { nome: "Rio Grande do Norte", artigo: "no" },
        RS: { nome: "Rio Grande do Sul", artigo: "no" },
        RO: { nome: "Rondônia", artigo: "em" },
        RR: { nome: "Roraima", artigo: "em" },
        SC: { nome: "Santa Catarina", artigo: "em" },
        SP: { nome: "São Paulo", artigo: "em" },
        SE: { nome: "Sergipe", artigo: "em" },
        TO: { nome: "Tocantins", artigo: "em" },
        ALL: { nome: "Brasil", artigo: "no" }
    };

    const estado = estados[sigla?.toUpperCase()];

    if (estado) {
        return estado;
    } else {
        return "Estado não encontrado.";
    }

}

export default nomeEstados