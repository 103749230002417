import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import { Modal } from "react-bootstrap"


import NavIcon from "../NavIcon";
import NavBadge from "../NavBadge";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../store/actions";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { LoadingContext } from '../../../../../SENAC/contexts/LoadingContext';

const NavItem = ({ layout, item, navLink = true }) => {

    const history = useHistory();
    const location = history.location;
    const path = location.pathname;
    const dispa = useDispatch();
    // Variável de contexto para dizer se o elemento está carregando ou não
    const [isLoading, updateLoading] = useContext(LoadingContext)

    const windowSize = useWindowSize();
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;

    const estado = useSelector(state => state.senac.estado);

    const [showModal, setShowModal] = useState(false);

    let itemTitle = item.title;
    if (item.icon) {
        itemTitle = <span className="pcoded-mtext">{item.title}</span>;
    }

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let iconClass = item.icon ? "nav-link removeBefore" : "nav-link"

    let subContent;
    if (item.external) {

        if (!navLink) {
            subContent = (
                <div>
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </div>
            )
        } else {
            subContent = (
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </a>
            );
        }

    } else if (item.modal) {
        subContent = (
            <a style={{cursor: "pointer"}} onClick={() => {
                setShowModal(true)
            }} rel='noopener noreferrer'>
                <NavIcon items={item} />
                {itemTitle}
                <NavBadge items={item} />
            </a>
        )
    }
    else {

        if (!navLink) {
            subContent = (
                <NavLink to={'#'} className={iconClass} >
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </NavLink>
            );
        } else {
            subContent = (
                <NavLink onClick={() => { 
                    if(!item.children) {
                        dispatch({ type: actionType.NAV_COLLAPSE_CLEAR });
                    }
                    if(item.url.includes("tecnicos")) {
                        dispa({ type: 'SET_OCUPACAO', value: {value: 'all', label:'Todas as ocupações'} });
                        dispa({ type: 'SET_CURSO_SELETOR_OCUPACAO', lista: [{value: 'all', label:'Todos os cursos técnicos'}] });
                    } else if(item.url.includes("qualificacao_profissional")) {
                        dispa({ type: 'SET_OCUPACAO', value: {value: 'allQuali', label:'Todas as ocupações'} });
                    }
                    console.log("hello:")
                    console.log(item.url)
                    if(  !path.includes('/setorial') || !item.url.includes("/setorial") || path.includes(item.url)  ) 
                        updateLoading('pageLoad', true) 

                }} to={item.omitirEstado ? item.url : item.url + '/' + estado} className={iconClass} exact={true} target={itemTarget}>
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </NavLink>
            );
        }

    }
    let mainContent = '';
    if (layout === 'horizontal') {
        mainContent = (
            <ListGroup.Item as='li' bsPrefix=' ' onClick={() => { updateLoading('pageLoad', true); dispatch({ type: actionType.NAV_CONTENT_LEAVE }) }}>{subContent}</ListGroup.Item>
        );
    } else {
        if (windowSize.width < 992) {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes} onClick={() => { updateLoading('pageLoad', true); dispatch({ type: actionType.COLLAPSE_MENU }) }}>{subContent}</ListGroup.Item>
            );
        } else {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes}>{subContent}</ListGroup.Item>
            );
        }
    }

    // Recebendo um modal

    const modal = (
        <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{item.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {item.content}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )


    return (
        <React.Fragment>
            {modal}
            {mainContent}
        </React.Fragment>
    );
};

export default NavItem;
