const DropdownCustomStyle = {
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        minHeight: '40px',
        height: '40px',
        cursor: 'pointer',
        // Media Query para tela menor que 720px
        '@media (max-width: 720px)': {
            width: '100%', // Altera o width para 100% quando a tela for menor que 650px
        },
    }),
};

export default DropdownCustomStyle;