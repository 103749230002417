import {axios, abortController} from '../../../config/axios-config'

const getVersoes = async () => {
    try {
        let response = null
        response = await axios.get('/getVersoes', {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
    }
}
export default getVersoes