import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getOcupacoesRelacionadas from '../../views/concorrencia/connection/getOcupacoesRelacionadas';
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { faHelmetSafety } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropdownOcupacoesRelacionadas = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const curso = useSelector(state => state.senac.curso);
  const estado = useSelector(state => state.senac.estado)
  const [ocupacoesRelacionadas, setOcupacoesRelacionadas] = useState(['Não tem nenhuma ocupação relacionada ao curso']);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let listaOcupacoesRelacionadas = await getOcupacoesRelacionadas(curso);
        if (listaOcupacoesRelacionadas !== undefined) {
          setOcupacoesRelacionadas(listaOcupacoesRelacionadas);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [curso]);

  const title = <FontAwesomeIcon icon={faHelmetSafety} />;

  return (
    <div className="divDropdown-button align-items-center" data-custom-pp="ocupacao">
      <OverlayTrigger
        placement='right'
        overlay={<Tooltip id={`tooltip-right`} className="custom-tooltip-right">Listar ocupações relacionadas ao curso</Tooltip>}
      >
        <DropdownButton
          title={title}
          className='drp-icon drp-button'
        >
          <Dropdown.Item className="drop-not-pointer" ><b>Ocupações relacionadas: </b></Dropdown.Item>
          {ocupacoesRelacionadas.map((ocupacao) => (
            <Dropdown.Item eventKey={ocupacao.NU_CODIGO_CBO}
              onClick={() => {
                dispatch({ type: 'SET_OCUPACAO', value: { value: ocupacao.NU_CODIGO_CBO, label: ocupacao.NM_OCUPACAO } });
                history.push(`/perfil_trabalhadores/tecnicos/${estado}`);
              }}>
              {ocupacao.NM_OCUPACAO}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </OverlayTrigger>
    </div>
  );
};

export default DropdownOcupacoesRelacionadas;