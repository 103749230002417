import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import NavLeft from "./NavLeft";
import NavRight from "./NavRight";

import nomeEstados from '../nomeEstados';

import { ConfigContext } from "../../../contexts/ConfigContext";
import * as actionType from "../../../store/actions";

import { Image } from 'react-bootstrap';
import logo from '../../../SENAC/assets/imagens/logo-senac.png'


const NavBar = () => {

    const history = useHistory();
    const location = history.location;
    const path = location.pathname;
    
    //const [moreToggle, setMoreToggle] = useState(false);
    const configContext = useContext(ConfigContext);
    const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
    const { dispatch } = configContext;

    const estado = useSelector(state => state.senac.estado);

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
    if (headerFixedLayout && layout === 'vertical') {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }
    
    if(path.includes("perfil_macrossetorial") || path.includes("relatorio")){
        headerClass = [...headerClass, 'header-smaller'];
    }

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = (e) => {
        e.preventDefault();
        dispatch({type: actionType.COLLAPSE_MENU})
    };

    // let moreClass = ['mob-toggler'];;
    // if (layout === 'horizontal') {
    //     moreClass = [...moreClass, 'd-none'];
    // }
    let collapseClass = ['collapse navbar-collapse'];
    // if (moreToggle) {
    //     //moreClass = [...moreClass, 'on'];
    //     collapseClass = [...collapseClass, 'd-block']
    // }

    let navBar = (
        <React.Fragment>
            <div className="m-header">
                <Link to='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}><span/></Link>
                <Link to='#' className="b-brand">
                    <div className="b-bg">
                       <Image className='custom-logo' src={logo} width={50} height={50}/>
                    </div>
                    <span className="b-title">Empregabilidade</span>
                    <span className="b-title navbar-subtitle">{nomeEstados(estado)?.nome}</span>
                </Link>
                {/* <Link to='#' className={moreClass.join(' ')} onClick={() => setMoreToggle(!moreToggle)}>
                    <i className="feather icon-more-vertical"/>
                </Link> */}
            </div>
            <div className={collapseClass.join(' ')}>
                <NavLeft/>
            </div>
        </React.Fragment>
    );

    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        navBar = (
            <div className="container">
                {navBar}
            </div>
        );
    }

    return (
        <React.Fragment>
            <header className={headerClass.join(' ')}>
                {navBar}
            </header>
        </React.Fragment>
    );
};

export default NavBar;
