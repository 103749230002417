import {axios, abortController} from '../../../config/axios-config'

const getOcupacoesRelacionadas = async (curso) => {
    try {
        let response = null
        response = await axios.post('/retornaOcupacoesRelacionadas', {curso: curso}, {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
    }
}
export default getOcupacoesRelacionadas