import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './services';

import { Provider } from 'react-redux';
import { ConfigProvider } from "./contexts/ConfigContext";

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import { LoadingProvider } from './SENAC/contexts/LoadingContext';


ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <LoadingProvider>
                <App />
            </LoadingProvider>
        </ConfigProvider>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();
