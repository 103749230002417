import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DropdownCurso from '../../../../SENAC/components/dropdown/DropdownCursos';
import DropdownOcupacoes from '../../../../SENAC/components/dropdown/DropdownOcupacoes';
import DropdownOcupacoesRelacionadas from '../../../../SENAC/components/dropdown/DropdownOcupacoesRelacionadas';
import DropdownCursosRelacionados from '../../../../SENAC/components/dropdown/DropdownCursosRelacionados';
import DropdownSetorAgrupado from '../../../../SENAC/components/dropdown/DropdownSetorAgrupado';
import DropdownSeletorCursos from '../../../../SENAC/components/dropdown/DropdownSeletorCursos';
import SelectInstituicoesFederais from '../../../../SENAC/components/form/SelectInstituicoesFederais';
import { useSelector } from 'react-redux';

const NavLeft = () => {
    const history = useHistory();
    const location = history.location;
    const path = location.pathname;
    const totalTrabalhadores = useSelector(state => state.senac.totalTrabalhadores);

    let dropContent = <></>
    let dropRelacionados = <></>
    let dropAlerta = <></>
    let dropCurso = <></>
    let selectInstituto = <></>

    let haveCursos = false;
    if (path.includes("perfil_trabalhadores")) {
        dropContent = <DropdownOcupacoes />
        if (path.includes("tecnicos")) {
            dropCurso = <DropdownSeletorCursos />
            dropRelacionados = <DropdownCursosRelacionados />
            haveCursos = true;
        }
        dropAlerta = (
            <>
                {totalTrabalhadores > 100 || totalTrabalhadores <= 1 ? null : (
                <div className="divDropdown-button align-items-center" data-custom-pp="trabalhador">
                    <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip className="custom-tooltip" id={`tooltip-bottom`}>{"Ocupação com poucos trabalhadores (<100) na região selecionada"}</Tooltip>}
                            >
                    <DropdownButton variant='warning'
                        disabled
                        title={<i className={'feather icon-alert-triangle mx-1'} />}
                        className={'drp-icon drp-button drp-alerta'}
                    >
                        <div className={'drp-item'}>
                            {"Ocupação com poucos trabalhadores (<100)"}
                        </div>
                    </DropdownButton>
                    </OverlayTrigger>
                </div>
                )}
            </>
        )

    } else if (path.includes("concorrencia")) {
        dropContent = <DropdownCurso />
        dropRelacionados = <DropdownOcupacoesRelacionadas />
        selectInstituto = <SelectInstituicoesFederais />
    } else if (path.includes("setorial/mercado_de_trabalho")) {
        dropContent = <DropdownSetorAgrupado />
        dropRelacionados = <DropdownCursosRelacionados />
        dropAlerta = (
            <>
                {totalTrabalhadores > 100 ? null : (
                <div className="divDropdown-button align-items-center" data-custom-pp="trabalhador">
                    <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip className="custom-tooltip" id={`tooltip-bottom`}>{"Setor com poucos trabalhadores (<100) na região selecionada."}</Tooltip>}
                            >
                    <DropdownButton variant='warning'
                        disabled
                        title={<i className={'feather icon-alert-triangle mx-1'} />}
                        className={'drp-icon drp-button drp-alerta'}
                    >
                        <div className={'drp-item'}>
                            {"Setor com poucos trabalhadores (<100) na região selecionada."}
                        </div>
                    </DropdownButton>
                    </OverlayTrigger>
                </div>
                )}
            </>
        )
    } else if(path.includes("setorial/empresas")) {
        dropContent = <DropdownSetorAgrupado />
        dropRelacionados = <DropdownCursosRelacionados />
    }

    return (
        <>
            <Row className="align-items-center" style={{ padding: "0px 0px 0px 25px", width: "100%" }}>
                <Col sm={6} hidden={haveCursos ? false : "hidden"}>
                    <Row>
                        <Col>
                            <div className='d-flex'>
                                {dropCurso}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} style={{ height: "100%" }}>
                    <div className='d-flex'>
                        <div className="col-dropdown-select flex-grow-1">
                            {dropContent}
                        </div>
                        <div className="col-dropdown-button-relacionado">
                            {dropRelacionados}
                            {dropAlerta}
                        </div>
                    </div>
                </Col>
            </Row>

            <div className="sel-institutos">
                {selectInstituto}
            </div>
        </>
    );

};
export default NavLeft;