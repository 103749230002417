import React, { useEffect, useState } from "react"
import getVersoes from "./connection/getVersoes"

const Versao = () => {

    const [listaVersoes, setListaVersoes] = useState([])
    
    useEffect(() => {
        const versoes = async () => {
            setListaVersoes(await getVersoes())
        }
        versoes()
    }, [])


    return (

        <div>
            {listaVersoes.map((versao, index) => {
                return (
                    <>
                        {index !== 0 ? <hr className="divisor-version" /> : ""}
                        <div>
                            {index !== 0 ? <i>Versão {versao.versao}</i> : ""}
                            <p>{versao.data}</p>
                            <p key={index}>
                                <div dangerouslySetInnerHTML={{ __html: versao.conteudo }} />
                            </p>
                        </div>
                    </>
                )
            })}
        </div>

    )


}

export default Versao