import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import nomeEstados from '../../nomeEstados';

import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import { LoadingContext } from '../../../../SENAC/contexts/LoadingContext';
import { Image } from 'react-bootstrap';
import logo from '../../../../SENAC/assets/imagens/logo-senac.png'

const NavLogo = () => {
    // Variável de contexto para dizer se o elemento está carregando ou não
    const [isLoading, updateLoading] = useContext(LoadingContext)

    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;
    const estado = useSelector(state => state.senac.estado);


    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = (e) => {
        e.preventDefault();
        dispatch({type: actionType.COLLAPSE_MENU})
    };

    return (
        <React.Fragment>
            <div className="navbar-brand header-logo">
                 <Link onClick={() => updateLoading('pageLoad', true)} to='/' className="b-brand">
                    <div className="b-bg">
                        <i className="iconLogo"></i>
                    </div>
                    <div className="title-container">
                        <span className="b-title">Empregabilidade</span>
                        <span className="b-title subtitle">{nomeEstados(estado)?.nome}</span>
                    </div>
                 </Link>
                <Link to='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}><span /></Link>
            </div>
        </React.Fragment>
    );
};

export default NavLogo;
