import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form'
import senacReducer from '../SENAC/redux/reducer';

const reducers = combineReducers({
    form: formReducer,
    senac: senacReducer
});

export default reducers;
