import initialState from './initialState';

const senacReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ESTADO':
      return {
        ...state,
        estado: action.value,
      }
    case 'SET_OCUPACAO':
      return {
        ...state,
        ocupacao: action.value,
      }
    case 'SET_CURSO':
      return {
        ...state,
        curso: action.value,
      }
    case 'SET_PROVENIENTE_QUALI':
      return {
        ...state,
        provenienteQuali: action.value,
      }
    case 'SET_CURSO_SELETOR_OCUPACAO':
      return {
        ...state,
        cursoSeletorOcupacao: action.lista,
      }
    case 'SET_SETOR_AGREGADO':
      return {
        ...state,
        setorAgregado: action.value,
      }
    case 'SET_APENAS_INSTITUTO':
      return {
        ...state,
        apenasInstitutos: action.value,
      }
    case 'SET_TOTAL_TRABALHADORES':
      return {
        ...state,
        totalTrabalhadores: action.value,
      }
    case 'SALVA_LISTA_ESCOLAS':
      return {
        ...state,
        listaEscolas: action.lista,
      }
    case 'SALVA_LISTA_EMPRESAS':
      return {
        ...state,
        listaEmpresas: action.lista,
      }
    case 'SALVA_FAMILIAS_OCUPACIONAIS':
      return {
        ...state,
        familiasOcupacionais: action.data,
      }
    case 'SET_EMPRESAS_ATIVAS':
      return {
        ...state,
        empresasAtivas: action.data,
      }
    case 'SET_CONTADOR_ERRO_BACKEND':
      return {
        ...state,
        contadorErroBackend: action.value,
      }
    case 'ADD_CONTADOR_ERRO_BACKEND':
      return {
        ...state,
        contadorErroBackend: state.contadorErroBackend + 1,
      }
    case 'SUBTRACT_CONTADOR_ERRO_BACKEND':
      return {
        ...state,
        contadorErroBackend: state.contadorErroBackend - 1,
      }
    default:
      return state;
  }
};

export default senacReducer;