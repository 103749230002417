import Versao from "./SENAC/components/versao";
import VersaoAtual from "./SENAC/components/versao/versaoAtual";

const menuItems = {
    items: [

        {
            id: 'macrossetorial',
            title: 'Macrossetorial',
            type: 'item',
            icon: 'fas fa-layer-group',
            url: '/perfil_macrossetorial'
        },
        {
            id: 'setorial',
            title: 'Setorial',
            type: 'collapse',
            icon: 'fas fa-city',
            active: true,
            children: [
                {
                    id: 'empresas',
                    title: 'Empresas',
                    type: 'item',
                    icon: 'fas fa-building',
                    children: true,
                    url: '/setorial/empresas'
                },
                {
                    id: 'mercado_de_trabalho',
                    title: 'Mercado de Trabalho',
                    type: 'item',
                    icon: 'fas fa-id-card-alt',
                    children: true,
                    url: '/setorial/mercado_de_trabalho'
                }
            ]
        },
        {
            id: 'perfil_trabalhadores',
            title: 'Perfil Trabalhadores',
            type: 'collapse',
            icon: 'fas fa-users',
            active: true,
            children: [
                {
                    id: 'tecnicos',
                    title: 'Técnicos',
                    type: 'item',
                    icon: 'fas fa-user-graduate',
                    children: true,
                    url: '/perfil_trabalhadores/tecnicos'
                },
                {
                    id: 'qualificacao_profissional',
                    title: 'Qualificação Profissional',
                    type: 'item',
                    icon: 'fas fa-user-alt',
                    children: true,
                    url: '/perfil_trabalhadores/qualificacao_profissional'
                }

            ]
        },
        {
            id: 'concorrencia',
            title: 'Concorrência',
            type: 'item',
            icon: 'fas fa-chess',
            url: '/concorrencia',
        },
        {
            id: 'relatorio',
            title: 'Relatório dinâmico',
            type: 'item',
            icon: 'fas fa-grip-horizontal',
            url: '/relatorio',
            classes: 'nav-item',
            breadcrumbs: false,
            omitirEstado: true
        },
        
        {
            id: 'contato',
            title: 'Contato',
            type: 'item',
            icon: 'fas fa-envelope',
            url: 'mailto:labfuturo@cos.ufrj.br',
            external: true,
            classes: 'nav-item',
            breadcrumbs: false
        },
        {
            id: 'versao',
            title: (<VersaoAtual />),
            type: 'item',
            icon: 'fas fa-arrow-alt-circle-up',
            url: '/versao',
            classes: 'nav-item',
            modal: true,
            content: (<Versao />),
            breadcrumbs: false
        }
    ]
};

export default menuItems;
