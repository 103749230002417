import { useEffect, useState } from "react";
import getVersaoAtual from "./connection/getVersaoAtual";
import versoesAntiga from "./versoes";

const VersaoAtual = () => {

    const [versaoAtual, setVersaoAtual] = useState();

    useEffect(() => {
        const versoes = async () => {
            setVersaoAtual(await getVersaoAtual())
            console.log(JSON.stringify(versoesAntiga))
        }
        versoes()
    }, [])
    return <>Versão {versaoAtual?.versao}</>
}

export default VersaoAtual;