import React, { createContext, useEffect, useState } from 'react';
import Loading from '../components/loading/Loading';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState({});

  const [status, setStatus] = useState("");

  const updateLoading = (key, value) => {

    isLoading[key] = value;
    if (Object.keys(isLoading).every(chave => isLoading[chave] === false)) {
      setStatus("stop");
    }
    else {
      setStatus("");
    }

  }

  return (
    <LoadingContext.Provider value={[isLoading, updateLoading]}>
      <Loading status={status} />
      {children}
    </LoadingContext.Provider>
  );
};