import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { FirebaseProvider } from "./contexts/FirebaseContext";
// import { JWTProvider } from "./contexts/JWTContext";
// import { Auth0Provider } from "./contexts/Auth0Context";
import ReactGA from "react-ga";

import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <FirebaseProvider>{renderRoutes(routes)}</FirebaseProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
