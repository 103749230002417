import { Grid } from '@agney/react-loading';

const Loading = ({status}) => {
    return (
        <div className={"loading__container " + status}>
            <div className="loading__container__spinner"><Grid width="50"/></div>
        </div>
    )
}

export default Loading;