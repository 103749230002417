const initialState = {
    estado: 'all',
    ocupacao: {value: 'all', label:'Todas as ocupações'},
    curso: {value: '81', label:'Técnico Aeroportuário'},
    cursoSeletorOcupacao: [{value: 'all', label:'Todos os cursos técnicos'}],
    setorAgregado: {value: 'all', label:'Todos os setores'},
    apenasInstitutos: false,
    totalTrabalhadores: 0,
    listaEscolas: [],
    listaEmpresas: [],
    familiasOcupacionais: [],
    contadorErroBackend: 0,
};

export default initialState;