import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getCursosRelacionados from '../../views/perfil_trabalhadores/connection/getCursosRelacionados';
import getCursosRelacionadosSetor from '../../views/visao_setorial/connection/getCursosRelacionadosSetor';
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { faChalkboardUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropdownCursosRelacionados = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location;
  const path = location.pathname;

  const cursoSeletorOcupacao = useSelector(state => state.senac.cursoSeletorOcupacao);
  const ocupacao = useSelector(state => state.senac.ocupacao);
  const codigoCbo = `${ocupacao?.value}`;
  const estado = useSelector(state => state.senac.estado)
  const setorAgregado = useSelector(state => state.senac.setorAgregado);

  const [cursosRelacionados, setCursosRelacionados] = useState(['Não tem nenhum curso relacionada a ocupação']);
  const [titleDrop, setTitleDrop] = useState("");
  const [toltipText, setToltipText] = useState("");
  const [toltipDirection, setToltipDirection] = useState(['right',`tooltip-right`]);

  let pathNow = 'trabalhador'
  useEffect(() => {
    const fetchData = async () => {
      try {
        let listaCursosRelacionados = [];
        if (path.includes("perfil_trabalhadores")) {
          listaCursosRelacionados = await getCursosRelacionados(codigoCbo, cursoSeletorOcupacao);
        } else {
          listaCursosRelacionados = await getCursosRelacionadosSetor(estado, setorAgregado.value);
        }
        if (listaCursosRelacionados !== undefined) {
          setCursosRelacionados(listaCursosRelacionados);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

  }, [codigoCbo, estado, setorAgregado, path, cursoSeletorOcupacao]);

  useEffect(() => {

    if (path.includes("perfil_trabalhadores")) {
      setToltipText("Listar cursos relacionados à ocupação");
      setTitleDrop("Cursos relacionados:");
      setToltipDirection(['left',`tooltip-left`]);
    } else if ((path.includes("setorial/mercado_de_trabalho")) || (path.includes("setorial/empresas"))) {
      setToltipText("Listar principais cursos relacionados ao setor");
      setTitleDrop("Principais cursos relacionados:");
      setToltipDirection(['right',`tooltip-right`]);
    }
  }, [path])

  const title = <FontAwesomeIcon icon={faChalkboardUser} />;

  if (path.includes("setorial")) {
    pathNow = 'setorial'
  }

  return (
    <div className="divDropdown-button align-items-center" data-custom-pp={pathNow}>
      <OverlayTrigger
        placement={toltipDirection[0]}
        overlay={<Tooltip id={toltipDirection[1]} className="custom-tooltip-right">{toltipText}</Tooltip>}
      >
        <DropdownButton
          title={title}
          className='drp-icon drp-button'
        >
          <Dropdown.Item className="drop-not-pointer" ><b>{titleDrop}</b></Dropdown.Item>
          {cursosRelacionados.map((curso) => (
            <Dropdown.Item
              onClick={() => {
                if (curso.CODIGO_CURSO !== "all") {
                  dispatch({ type: 'SET_CURSO', value: { value: curso.CODIGO_CURSO, label: curso.Titulo } });
                  history.push(`/concorrencia/${estado}`);
                }
              }}>
              {curso.Titulo}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </OverlayTrigger>
    </div>
  );
};

export default DropdownCursosRelacionados;