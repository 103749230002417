import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { LoadingContext } from '../../SENAC/contexts/LoadingContext';

import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Configuration from "./Configuration";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import ModalVersaoAtual from '../../SENAC/components/versao/modalVersaoAtual';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import html2pdf from 'html2pdf.js';

const AdminLayout = ({ children }) => {

    const [isLoading, updateLoading] = useContext(LoadingContext)

    const windowSize = useWindowSize();
    const ref = useRef();
    const configContext = useContext(ConfigContext);

    const { collapseMenu, layout, subLayout, headerFixedLayout, configBlock } = configContext.state;
    const { dispatch } = configContext;

    const [gerarPdfLoading, setGerarPdfLoading] = useState(false)
    const [sizeToPrint, setSizeToPrint] = useState('100%')
    const [relatorioSize, setRelatorioSize] = useState('98%')

    useEffect(() => {
        if (!gerarPdfLoading) updateLoading('gerarPdf', false)
    }, [gerarPdfLoading])

    useEffect(() => {
        if (sizeToPrint !== '100%') {
            setGerarPdfLoading(true)

            // Necessário para esperar o gráfico renderizar
            setTimeout(async () => {
                await gerarPdf()
                setSizeToPrint('100%')
                const relatorioElements = document.querySelectorAll('.react-grid-item.relatorio-card')
                if (relatorioElements) {
                    relatorioElements.forEach(element => {
                        element.style.width = '100%';
                    });
                    setRelatorioSize('98%')
                }
                setGerarPdfLoading(false)
            }, 1000);

        }
    }, [sizeToPrint])

    const contentRef = useRef(null);

    const gerarPdf = async () => {
        let content = contentRef.current;

        if (content) {

            const canvasElements = content.querySelectorAll('canvas');
            canvasElements.forEach(function (canvas) {
                if (canvas.width === 0 || canvas.height === 0) {
                    canvas.width = 1;
                    canvas.height = 1;
                }
            });

            const rootElement = document.getElementById('root');
            const alturaDoConteudo = rootElement ? rootElement.offsetHeight : 0;


            const pdfOptions = {
                margin: 5,
                filename: 'documento.pdf',
                image: { type: 'jpeg', quality: 0.30 },
                html2canvas: { scale: 4, x: 280, width: 1830  },
                jsPDF: { orientation: 'p', format: [1830, alturaDoConteudo], unit: 'px' },
            };

            try {
                await html2pdf(content, pdfOptions)
            } catch (error) {
                console.error('Erro ao gerar o PDF:', error)
            }
        }
    }


    useOutsideClick(ref, () => {
        if (collapseMenu) {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }
    });

    useEffect(() => {
        if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }

        if (layout === 'horizontal' && windowSize.width < 992) {
            dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
        }
    }, [dispatch, layout, windowSize]);

    const mobileOutClickHandler = () => {
        if (windowSize.width < 992 && collapseMenu) {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }
    };

    let mainClass = ['pcoded-wrapper'];
    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        mainClass = [...mainClass, 'container'];
    }

    let common = (
        <React.Fragment>
            <Navigation />
        </React.Fragment>
    );

    let mainContainer = (
        <React.Fragment>
            <div ref={contentRef} style={{ width: sizeToPrint }}>
                <NavBar />
                <div className="pcoded-main-container"  >
                    <div className={mainClass.join(' ')}>
                        <div className="pcoded-content">
                            <div className="pcoded-inner-content">

                                <Breadcrumb />
                                {children}
                                {gerarPdfLoading ?
                                    <Row>
                                        {/*
                                    <Col md={12} xl={12} sm={12} lg={12} style={{textAlign: "right", paddingRight: "4px"}}>
                                        <Button disabled 
                                        style={{
                                            backgroundColor: "#004A8D", 
                                            borderColor: "#004A8D"
                                        }}>
                                            <span className="spinner-border spinner-border-sm mr-1" role="status" />Gerando PDF...
                                        </Button>
                                    </Col>
                                */}
                                    </Row>
                                    :
                                    <Row>

                                        <Col md={12} xl={12} sm={12} lg={12} style={{ textAlign: "right", paddingRight: "4px" }}>
                                            <Button variant={"primary"} className='text-capitalize'
                                                style={{
                                                    backgroundColor: "#004A8D",
                                                    borderColor: "#004A8D"
                                                }}
                                                onClick={() => {
                                                    updateLoading('gerarPdf', true)
                                                    // Quando tiver gerando o PDF do relatório, o tamanho do relatório deve ser ajustado
                                                    const relatorioElements = document.querySelectorAll('.react-grid-item.relatorio-card')
                                                    if (relatorioElements) {
                                                        relatorioElements.forEach(element => {
                                                            setRelatorioSize(element.style.width)
                                                            element.style.width = '98%';
                                                        });
                                                    }
                                                    setSizeToPrint('1600pt')
                                                }}>
                                                <FontAwesomeIcon icon={faFilePdf} />
                                                {" Gerar PDF"}
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                        <div className='card-footer'>
                            Copyright © 2023. Desenvolvido por <a href='https://labfuturo.cos.ufrj.br/' target='_blank'>Laboratório do Futuro da COPPE/UFRJ</a> e <a href='https://www.labore.tech/' target='_blank'>LABORe</a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    if (windowSize.width < 992) {
        let outSideClass = ['nav-outside'];
        if (collapseMenu) {
            outSideClass = [...outSideClass, 'mob-backdrop'];
        }
        if (headerFixedLayout) {
            outSideClass = [...outSideClass, 'mob-fixed'];
        }

        common = (
            <div className={outSideClass.join(' ')} ref={ref}>
                {common}
            </div>
        );

        mainContainer = (
            <div className='pcoded-outside' onClick={() => mobileOutClickHandler}>
                {mainContainer}
            </div>
        );
    }

    return (
        <React.Fragment>
            {common}
            <ModalVersaoAtual />
            {mainContainer}
            {configBlock && <Configuration />}
        </React.Fragment>
    );
};

export default AdminLayout;
