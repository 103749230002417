import {axios, abortController} from '../../../config/axios-config'

const getCursosRelacionadosSetor = async (estado, setor) => {
    try {
        let response = null
        response = await axios.post('/retornaCursosPopulares', {uf: estado, setorAgregado: setor}, {cancelToken: abortController.token, timeout: 60000})
        return response.data
    } catch (error) {
        console.log(error)
    }
}
export default getCursosRelacionadosSetor