import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getCursosCNCT from '../../views/concorrencia/connection/getCursosCNCT';
import DropdownCustomStyle from './DropdownCustomStyle';
import Select from "react-select";
import { Form } from 'react-bootstrap';


const DropdownCurso = () => {

  const dispatch = useDispatch();

  const curso = useSelector(state => state.senac.curso);

  const [cursosCNCT, setCursosCNCT] = useState(["Não tem nenhum curso no sistema"])
  const [selectedOption, setSelectedOption] = useState(curso);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let listaCursos = await getCursosCNCT();
        if (listaCursos !== undefined) {
          setCursosCNCT(listaCursos);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    
  }, []);

  useEffect(() => {
    setSelectedOption(curso)
  }, [curso]);

  return (
    <div className="divDropdown">
      <Form.Label className='divDropDownTitle'>Curso</Form.Label>
      <Select
        isSearchable={true}
        options={cursosCNCT.map((curso) => {
          return { value: curso.CODIGO_CURSO, label: curso.Titulo }
        })}
        value={selectedOption}
        onChange={(e) => {
          dispatch({ type: "SET_CURSO", value: e });
          setSelectedOption(e);
        }}
        className="dropdown-item-Custom col-dropdown-select"
        classNamePrefix="select"
        styles={DropdownCustomStyle}
      />
    </div>
  );
};

export default DropdownCurso;